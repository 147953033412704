const SERVICES = {
  API: Symbol('Api'),
  SESSION: Symbol('Session'),
  MAP: Symbol('Map'),
  TRACKING: Symbol('Tracking'),
  URL: Symbol('Url'),
  FORMAT: Symbol('Format')
};

export default SERVICES;
