import 'reflect-metadata';
import { Container } from 'inversify';
import SERVICES from './Services';

// Interfaces
import Session from '../interfaces/Session';
import Map from '../interfaces/Map';
import Tracking from '../interfaces/Tracking';
import Url from '../interfaces/Url';
import Format from '../interfaces/Format';
import Api from '../interfaces/Api';

// Providers
import SessionProvider from '../providers/SessionProvider';
import MapProvider from '../providers/MapProvider';
import TrackingProvider from '../providers/TrackingProvider';
import UrlProvider from '../providers/UrlProvider';
import FormatProvider from '../providers/FormatProvider';
import ApiProvider from '../providers/ApiProvider';

// Container
const iocContainer = new Container();

// Bind Providers to Interfaces
iocContainer.bind<Session>(SERVICES.SESSION).to(SessionProvider);
iocContainer.bind<Map>(SERVICES.MAP).to(MapProvider);
iocContainer.bind<Tracking>(SERVICES.TRACKING).to(TrackingProvider);
iocContainer.bind<Url>(SERVICES.URL).to(UrlProvider);
iocContainer.bind<Format>(SERVICES.FORMAT).to(FormatProvider);
iocContainer.bind<Api>(SERVICES.API).to(ApiProvider);

export default iocContainer;
