





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  props: {
    commodity: String
  }
})
export default class CommoditySwitcher extends Vue {
  public commodityChanged(event: Event): void {
    this.$emit('commodityChanged', event);
  }
}
