import { injectable } from 'inversify';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Session from '../interfaces/Session';

@injectable()
export default class SessionProvider implements Session {
  public http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/v1/',
      headers: {
        Accept: 'application/json',
        Authorization: process.env.VUE_APP_API_AUTH,
        'WD-OVERWRITE-POWERCLOUD-APIS': process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true'
      }
    });
  }

  public async sessionRead(sessionToken: string): Promise<AxiosResponse<any>> {
    return await this.http.post('/session', { sessionHash: sessionToken, isWidget: true });
  }

  public async create(
    widget: string,
    campaign: string,
    business: number,
    usage: number | null,
    usageNt: number | null,
    consumptionType: string,
    priceType: string,
    commodity: string,
    zip: string,
    city: string,
    state: string | null,
    country: string | null,
    cityId: string,
    street: string | null,
    houseNumber: string | null,
    vpCode: string | null,
    uvpCode: string | null,
    customerId: number | null,
    productCode: string | null,
    addon: string | null,
    subCommodity: string | null,
    extendedData: Record<any, any> | null,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    genericStorage: any
  ): Promise<AxiosResponse<any>> {
    return await this.http.post('/session/create', {
      widget,
      campaign,
      business,
      usage,
      usageNt,
      consumptionType,
      priceType,
      commodity,
      zip,
      city,
      state,
      country,
      cityId,
      street,
      houseNumber,
      vpCode,
      uvpCode,
      customerId,
      productCode,
      addon,
      subCommodity,
      extendedData,
      genericStorage
    });
  }

  public async update(
    sessionHash: string,
    widget: string,
    campaign: string,
    usage: number | null,
    zip: string,
    city: string,
    state: string | null,
    country: string | null,
    cityId: string,
    street: string,
    houseNumber: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    genericStorage: any
  ): Promise<AxiosResponse<any>> {
    return await this.http.post('/session/update/widget', {
      sessionHash,
      widget,
      campaign,
      usage,
      zip,
      city,
      state,
      country,
      cityId,
      street,
      houseNumber,
      genericStorage
    });
  }

  public async updateCommodity(
    sessionHash: string,
    widget: string,
    campaign: string,
    usage: number | null,
    commodity: string,
    zip: string,
    city: string,
    state: string | null,
    country: string | null,
    cityId: string,
    street: string,
    houseNumber: string,
    subCommodity: string | null
  ): Promise<AxiosResponse<any>> {
    return await this.http.post('/session/update/commodity', {
      sessionHash,
      widget,
      campaign,
      usage,
      commodity,
      zip,
      city,
      state,
      country,
      cityId,
      street,
      houseNumber,
      subCommodity
    });
  }

  public async submitWidget(
    sessionHash: string,
    usage: number,
    usageNt: number | null,
    consumptionType: string,
    priceType: string,
    street: string,
    houseNumber: string,
    withProduct: boolean
  ): Promise<AxiosResponse<any>> {
    return await this.http.post('/session/widget/submit', {
      sessionHash,
      usage,
      usageNt,
      consumptionType,
      priceType,
      street,
      houseNumber,
      withProduct
    });
  }
}
