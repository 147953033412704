import { injectable } from 'inversify';
import moment from 'moment';
import Format from '@/interfaces/Format';

@injectable()
export default class FormatProvider implements Format {
  public formats: any = {
    date: {
      de: 'DD.MM.YYYY',
      us: 'MM-DD-YYYY'
    },
    datetime: {
      de: 'DD.MM.YYYY HH:mm',
      us: 'MM-DD-YYYY HH:mm'
    },
    euro: {
      de: 'de-DE',
      us: 'en-US'
    }
  };

  public date(): (d: string) => string {
    const format = this.getFormat('date');
    return function date(d) {
      if (d !== null) {
        return moment(d).format(format);
      } else {
        return '';
      }
    };
  }

  public datetime(): (d: string) => string {
    const format = this.getFormat('datetime');
    return function datetime(d) {
      if (d !== null) {
        return moment(d).format(format);
      } else {
        return '';
      }
    };
  }

  public dateTechnical(): (d: string) => string {
    return function dateTechnical(d) {
      if (d !== null) {
        return moment(d).format('YYYY-MM-DD');
      } else {
        return '';
      }
    };
  }

  public euro(): (n: string) => string {
    const format = this.getFormat('euro');
    return function euro(n) {
      if (n !== null) {
        return parseFloat(n).toLocaleString(format, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        return '';
      }
    };
  }

  public euroFull(): (n: string) => string {
    const format = this.getFormat('euro');
    return function euroFull(n) {
      if (n !== null) {
        return Number(n).toLocaleString(format, {
          maximumFractionDigits: 0
        });
      } else {
        return '';
      }
    };
  }

  public euroThree(): (n: string) => string {
    const format = this.getFormat('euro');
    return function euro(n) {
      if (n !== null) {
        return parseFloat(n).toLocaleString(format, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3
        });
      } else {
        return '';
      }
    };
  }

  public withoutCents(): (n: number) => string | number {
    return function withoutCents(n) {
      if (n !== null) {
        return Math.floor(n);
      } else {
        return '';
      }
    };
  }

  public remainingCents(): (n: number) => string | number {
    return function remainingCents(n) {
      if (n !== null) {
        return Math.round(n * 100)
          .toString()
          .slice(-2);
      } else {
        return '';
      }
    };
  }

  public zip(): (n: string) => string {
    return function zip(n) {
      if (n && n !== null && n.length < 5) {
        return '0' + n;
      } else {
        return n;
      }
    };
  }

  public iban(): (n: string) => string {
    return function iban(n) {
      return n.replace(/(.{6})(.*)(?=.{4})/, (gp1, gp2, gp3) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const gp of gp3) {
          gp2 += 'X';
        }
        return gp2;
      });
    };
  }

  private getFormat(type) {
    let country = process.env.VUE_APP_COUNTRY_CODE;
    if (!country) {
      country = 'de';
    }

    const format = this.formats[type];
    if (format) {
      if (this.formats[type][country]) {
        return this.formats[type][country];
      } else {
        return this.formats[type]['de'];
      }
    }

    return '';
  }
}
