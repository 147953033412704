

































import { Component, Watch } from 'vue-property-decorator';
import vSelect from 'vue-select';

import Widget from '@/components/basic/Widget.vue';

@Component({
  props: {
    streets: Array,
    errorHouseNumber: Boolean
  },
  components: {
    'v-select': vSelect
  }
})
export default class AddressBlock extends Widget {
  public houseNumber: number | null = null;

  // The maximum number of characters can be placed to the street field is 17
  protected isMaximumCharacters = false;

  get streetsDisplay(): [] {
    return this.$props.streets.map((street) => {
      return street.name;
    });
  }

  @Watch('houseNumber')
  protected onHouseNumberChanges(): void {
    this.$emit('houseNumberInput', this.houseNumber);
  }

  protected streetInput(value: string): void {
    if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
      this.isMaximumCharacters = value.length > 14;
      this.$emit('elliMaximumCharacters', this.isMaximumCharacters);
    }
    this.$emit('streetInput', value);
  }
}
