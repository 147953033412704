















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@Component({
  props: {
    sessionNotifierDisplayOverlay: {
      type: Boolean,
      default: true
    },
    sessionToken: {
      type: String,
      default: ''
    },
    sessionredirectparam: {
      type: String,
      default: 'session'
    },
    redirect: {
      type: String,
      default: ''
    },
    sessionUpdatedAt: {
      type: String,
      default: ''
    }
  },
  filters: {
    datetime: formatProvider.datetime()
  }
})
export default class SessionNotifier extends Vue {
  public sessionNotifierDisplayOverlay;
  public sessionToken;
  public sessionredirectparam;
  public redirect;
  public sessionUpdatedAt;

  private clickHandler() {
    this.sessionNotifierDisplayOverlay = !this.sessionNotifierDisplayOverlay;
  }
}
