



































































import { Component, Watch } from 'vue-property-decorator';
import vSelect from 'vue-select';

import Widget from '@/components/basic/Widget.vue';

@Component({
  props: {
    separatedZip: {
      type: Boolean,
      default: false
    },
    citySaved: {
      type: String,
      default: ''
    },
    cityPlaceholder: {
      type: String,
      default: ''
    },
    citiesDisplay: Array,
    zip: String,
    city: String
  },
  components: {
    'v-select': vSelect
  }
})
export default class City extends Widget {
  [x: string]: any;

  public localCitySaved = '';

  protected localZip = '';
  protected localCity = '';

  public mounted(): void {
    if (this.$props.citySaved !== '') {
      this.localCitySaved = this.$props.citySaved;
    }
  }

  public citySearch(value: string): void {
    this.$emit('citySearch', value);
  }

  public citiesInput(value: string): void {
    this.$emit('citiesInput', value);
  }

  @Watch('$props.zip')
  protected handlePropsZip(value: string): void {
    this.localZip = value;
  }

  @Watch('$props.city')
  protected handlePropsCity(value: string): void {
    this.localCity = value;
  }

  @Watch('localZip')
  protected onZipChange(newValue: string): void {
    if (!newValue) {
      this.localCity = '';
    }
  }

  @Watch('$props.citySaved')
  protected handleCitySaved(citySaved: string): void {
    if (citySaved !== '') {
      this.localCitySaved = citySaved;
    }
  }
}
