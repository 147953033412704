























































































































































import { Component, Watch } from 'vue-property-decorator';
import Default from '@/main/Default.vue';

import vSelect from 'vue-select';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import WdSalesWidgetInterface from '@/interfaces/WdSalesWidget';
import Map from '@/interfaces/Map';

import UserRegular from '@/components/UserRegular.vue';
import UserSolid from '@/components/UserSolid.vue';

import SubmitButton from '@/components/basic/SubmitButton.vue';
import CommoditySwitcher from '@/components/basic/CommoditySwitcher.vue';
import Usage from '@/components/basic/Usage.vue';
import HeatingUsage from '@/components/dsp-flex/HeatingUsage.vue';
import SessionNotifier from '@/components/SessionNotifier.vue';
import HeatingOption from '@/interfaces/HeatingOption';

@Component({
  components: {
    HeatingUsage,
    'v-select': vSelect,
    UserRegular,
    UserSolid,
    CommoditySwitcher,
    Usage,
    SubmitButton,
    'session-notifier': SessionNotifier
  }
})
export default class DSPFlex extends Default implements WdSalesWidgetInterface {
  [x: string]: any;
  protected cities: any = [];
  protected streets: any = [];

  public isSmallLayout = ['dsp-ahrensburg', 'dsp-revulution'].includes(process.env.VUE_APP_ENVIRONMENT ?? '');

  protected streetNeeded = false;
  protected searchLengthReached = false;
  protected searchQuery = '';

  protected map = IocContainer.get<Map>(SERVICES.MAP);

  protected usageList = [1700, 2500, 3500, 4500];

  protected singleIcon = false;

  protected houseSquares: number[] = [50, 100, 150, 250];

  protected maxUsage = 99999;

  protected heatingSelected: HeatingOption = {
    label: this.$t('heat_pump') + ', ' + this.$t('single_price'),
    consumptionType: 'heat_pump',
    priceType: 'single_price'
  };

  private tempUsage: number | null = null;
  private showMaxConsumptionError = false;

  get submitAllowed(): boolean {
    return !!(
      this.sessionToken &&
      this.city &&
      this.commodity &&
      this.usage &&
      (this.priceType === 'single_price' || this.usageNt) &&
      (!this.streetNeeded || (this.street && this.houseNumber))
    );
  }

  get displayCommoditySwitcher(): boolean {
    return process.env.VUE_APP_DISPLAY_COMMODITY_SWITCHER
      ? process.env.VUE_APP_DISPLAY_COMMODITY_SWITCHER === '1'
      : true;
  }

  get usageIcon(): string {
    if (this.commodity == 'gas') {
      return 'dsp-house';
    }

    return 'user-solid-reg';
  }

  get errorConsumption(): string {
    if (this.commodity === 'gas' && process.env.VUE_APP_ENVIRONMENT === 'dsp-ahrensburg') {
      return this.$t('errorMaxConsumption.gas').toString();
    } else {
      return this.$t('errorMaxConsumption').toString();
    }
  }

  public created(): void {
    if (this.commodity == 'gas') {
      this.singleIcon = true;
      this.usageList = [5000, 12000, 18000, 35000];
      if (process.env.VUE_APP_ENVIRONMENT === 'dsp-ahrensburg') {
        this.maxUsage = 250000;
      }
    }
    if (this.commodity === 'heating') {
      this.consumptionType = 'heat_pump';
    }
  }

  public validate(): void {
    this.errorCommodity = this.commodity === '';

    if (this.usage === null || (this.priceType !== 'single_price' && this.usageNt === null)) {
      this.errorUsage = true;
      this.errorUsageLimit = null;
    } else if (this.isBusiness === 0 && this.usage > 50000 && process.env.VUE_APP_ENVIRONMENT === 'qcells') {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit').toString();
      this.trackingProvider.sessionUsageLimitHigh();
    } else if (this.isBusiness === 1 && this.usage < 1000 && process.env.VUE_APP_ENVIRONMENT === 'qcells') {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit2').toString();
      this.errorUsageLimit = '';
      this.trackingProvider.sessionUsageLimitBusinessLow();
    } else if (this.isBusiness === 1 && this.usage >= 100000 && process.env.VUE_APP_ENVIRONMENT === 'qcells') {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit').toString();
      this.trackingProvider.sessionUsageLimitBusinessHigh();
    } else if (
      this.usage > 10000 &&
      this.commodity === 'electricity' &&
      process.env.VUE_APP_ENVIRONMENT === 'sachsenenergie'
    ) {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit').toString();
      this.trackingProvider.sessionUsageLimitHigh();
    } else if (this.usage > 50000 && this.commodity === 'gas' && process.env.VUE_APP_ENVIRONMENT === 'sachsenenergie') {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit').toString();
      this.trackingProvider.sessionUsageLimitHigh();
    } else if (
      this.usage > 250000 &&
      this.commodity === 'gas' &&
      process.env.VUE_APP_ENVIRONMENT === 'dsp-ahrensburg'
    ) {
      this.errorUsage = false;
      this.errorUsageLimit = this.$t('errorUsageLimit').toString();
      this.trackingProvider.sessionUsageLimitHigh();
    } else {
      this.errorUsage = false;
      this.errorUsageLimit = null;
    }

    this.errorCity = this.city === '';
    this.errorStreet = this.streetNeeded && (this.street === '' || this.street === null);
    this.errorHouseNumber = this.streetNeeded && (this.houseNumber === '' || this.houseNumber === null);
  }

  public canCreateSession(): boolean {
    return !this.errorCommodity && !this.errorCity;
  }

  public canUpdateSession(): boolean {
    return !this.errorCommodity && !this.errorCity && !!this.sessionToken;
  }

  @Watch('street')
  @Watch('houseNumber')
  protected onAddressChange(): void {
    if (this.street !== '' && this.houseNumber !== '') {
      this.streetNeeded = true;
    }
  }

  @Watch('commodity')
  protected handleCommodityChange(): void {
    if (this.commodity == 'gas') {
      this.singleIcon = true;
      this.usageList = [5000, 12000, 18000, 35000];
    } else {
      this.singleIcon = false;
      this.usageList = [1700, 2500, 3500, 4500];
    }
  }

  protected onUsageChanged(componentRef: Usage): void {
    const newValue = componentRef.usage;
    if (this.commodity === 'gas' && process.env.VUE_APP_ENVIRONMENT === 'dsp-ahrensburg') {
      if (newValue > 250000) {
        this.user = 4;
        this.showMaxConsumptionError = true;
        componentRef.usage = 250000;
        this.tempUsage = this.usage = componentRef.usage;
      } else if (this.tempUsage !== null && this.tempUsage > 99999) {
        this.tempUsage = null;
        this.showMaxConsumptionError = true;
        componentRef.usage = this.usage = newValue;
      } else {
        this.showMaxConsumptionError = false;
        this.usage = newValue;
      }

      if (this.usage !== null) {
        if (this.usage > 0 && this.usage < 12000) {
          this.user = 1;
        } else if (this.usage >= 12000 && this.usage < 18000) {
          this.user = 2;
        } else if (this.usage >= 18000 && this.usage < 35000) {
          this.user = 3;
        } else if (this.usage >= 35000) {
          this.user = 4;
        }
      }
    } else {
      if (newValue > 99999) {
        this.user = 4;
        this.showMaxConsumptionError = true;
        componentRef.usage = 99999;
        this.tempUsage = this.usage = componentRef.usage;
      } else if (this.tempUsage !== null && this.tempUsage > 99999) {
        this.tempUsage = null;
        this.showMaxConsumptionError = true;
        componentRef.usage = this.usage = 99999;
      } else {
        this.showMaxConsumptionError = false;
        this.usage = newValue;
      }
    }
    this.updateSelectedUsers(componentRef);
  }

  protected onUsageNtChanged(componentRef: HeatingUsage): void {
    this.usageNt = componentRef.usageNt;
  }
}
