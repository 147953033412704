




























































import Usage from '@/components/basic/Usage.vue';
import Component from 'vue-class-component';

@Component({
  props: {
    priceType: String
  }
})
export default class HeatingUsage extends Usage {
  public usageNt = 0;

  private onUsageNtInput(): void {
    this.$emit('onUsageNtChanged', this);
    this.$emit('usageNtChanged', parseInt('' + this.usageNt, 10));
  }
}
