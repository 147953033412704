
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  props: {
    withLabel: {
      type: Boolean,
      default: false
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    classStreet: String,
    classHouse: String,
    classMain: String,
    classCity: String,
    classZip: String,
    business: Boolean
  }
})
export default class Widget extends Vue {
  get showBottomLabel(): boolean {
    return this.$props.withLabel && this.$props.labelPosition === 'bottom';
  }

  get showTopLabel(): boolean {
    return this.$props.withLabel && this.$props.labelPosition === 'top';
  }
}
