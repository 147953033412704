import { injectable } from 'inversify';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Map from '../interfaces/Map';

@injectable()
export default class MapProvider implements Map {
  public http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/v1/',
      headers: {
        Accept: 'application/json',
        Authorization: process.env.VUE_APP_API_AUTH
      }
    });
  }

  public async cities(): Promise<AxiosResponse<any>> {
    return await this.http.get('/cities');
  }
}
