





























































































import Widget from '@/components/basic/Widget.vue';
import { Component, Watch } from 'vue-property-decorator';

import UserSolid from '@/components/UserSolid.vue';
import UserRegular from '@/components/UserRegular.vue';

import DspHouse from '@/components/DspHouse.vue';

@Component({
  props: {
    defaultUsage: Number,
    usageList: Array,
    icons: {
      type: String,
      default: 'user-solid-reg'
    },
    forceActive: {
      type: Number,
      default: 0
    },
    errorUsage: Boolean,
    isFormattedNumber: {
      type: Boolean,
      default: false
    },
    houseSquares: Array,
    singleIcon: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 99999
    }
  },
  components: {
    UserSolid,
    UserRegular,
    DspHouse
  }
})
export default class Usage extends Widget {
  public usage = 0;

  protected user = 0;

  get showHouseSquares(): boolean {
    return this.$props.singleIcon && this.$props.icons == 'dsp-house';
  }

  get formattedUsage(): string | number {
    if (this.$props.isFormattedNumber) {
      return this.formattedNumber(this.usage);
    } else {
      return this.usage;
    }
  }

  set formattedUsage(newValue: string | number) {
    this.usage = Math.random(); // reset to update getter
    this.usage = parseInt((newValue + '').replace(/\D+/g, ''), 10);
  }

  public onUserClick(user: number): void {
    this.usage = this.$props.usageList[user];
    this.user = user + 1;
    this.$emit('onUpdateSelectedUsers', this);
    this.$emit('usageChanged', parseInt('' + this.usage, 10));
  }

  private onUsageInput(): void {
    this.$emit('onUsageChanged', this);
    this.$emit('usageChanged', parseInt('' + this.usage, 10));
  }

  public isActive(item: number): boolean {
    if (this.$props.forceActive) {
      return item === this.$props.forceActive;
    }
    return item === this.user;
  }

  public mounted(): void {
    this.usage = this.$props.defaultUsage;
  }

  public getIconComponent(isActive: boolean): string {
    if (this.$props.icons === 'user-solid-reg') {
      if (isActive) {
        return 'UserSolid';
      } else {
        return 'UserRegular';
      }
    }

    return this.$props.icons;
  }

  @Watch('$props.defaultUsage')
  public handleDefaultUsage(value: number): void {
    this.usage = value;
  }

  @Watch('usage')
  public handleUsageChanged(value: number): void {
    this.$emit('usageChanged', value);
  }

  @Watch('$props.forceActive')
  public handleForceActiveChange(value: number): void {
    this.user = value;
  }

  private formattedNumber(value: number): string {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
  }
}
