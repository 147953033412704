























































































import Prototype from '@/main/Prototype.vue';
import { Component, Watch } from 'vue-property-decorator';
import * as Sentry from '@sentry/browser';

import vSelect from 'vue-select';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import WdSalesWidgetInterface from '@/interfaces/WdSalesWidget';
import Map from '@/interfaces/Map';

import UserRegular from '@/components/UserRegular.vue';
import UserSolid from '@/components/UserSolid.vue';

import SubmitButton from '@/components/basic/SubmitButton.vue';
import CommoditySwitcher from '@/components/basic/CommoditySwitcher.vue';
import Usage from '@/components/basic/Usage.vue';
import AddressBlock from '@/components/basic/AddressBlock.vue';
import City from '@/components/basic/City.vue';
import SessionNotifier from '@/components/SessionNotifier.vue';
@Component({
  components: {
    'v-select': vSelect,
    UserRegular,
    UserSolid,
    CommoditySwitcher,
    Usage,
    AddressBlock,
    City,
    SubmitButton,
    'session-notifier': SessionNotifier
  }
})
export default class Default extends Prototype implements WdSalesWidgetInterface {
  [x: string]: any;

  protected map = IocContainer.get<Map>(SERVICES.MAP);

  protected usageList: any = [2000, 3100, 3800, 4200];
  protected usageMap: { gas: number[]; electricity: number[] } = {
    gas: [2000, 3100, 3800, 4200],
    electricity: [2000, 3100, 3800, 4200]
  };
  protected user = 0;
  protected currentZipInFilter: any = null;
  protected errorUsageLimit: string | null = null;

  protected updateSelectedUsers(componentRef: Usage): void {
    if (componentRef.usage !== null) {
      if (componentRef.usage > 0 && componentRef.usage < this.usageList[1]) {
        this.user = 1;
      } else if (componentRef.usage >= this.usageList[1] && componentRef.usage < this.usageList[2]) {
        this.user = 2;
      } else if (componentRef.usage >= this.usageList[2] && componentRef.usage < this.usageList[3]) {
        this.user = 3;
      } else if (componentRef.usage >= this.usageList[3]) {
        this.user = 4;
      }
    }
    this.usage = componentRef.usage;
  }

  protected onUsageChanged(componentRef: Usage): void {
    this.usage = componentRef.usage;
    this.updateSelectedUsers(componentRef);
  }

  public syncCommodityWithUsage() {
    this.usageList = this.usageMap[this.commodity];
    this.usage = this.usageList[1];
  }

  public created(): void {
    console.log('WdSalesWidget created');

    this.map.cities().then(
      (response) => {
        this.cities = response.data.cities;
        if (process.env.VUE_APP_COUNTRY_CODE === 'us') {
          // Custom Code for US Demo
          this.cities.map((city) => {
            if (city.zip === '77746') {
              city.name = 'Atlanta';
              city.text = '77746 Atlanta';
            }
            return city;
          });
        }
        if (this.preSetZip && this.preSetZip !== '') {
          const preSetCity = this.cities.filter((city) => {
            return parseInt(city.zip, 10) === parseInt(this.preSetZip, 10);
          });
          if (preSetCity[0]) {
            this.citySaved = preSetCity[0].zip + ' ' + preSetCity[0].name;
            this.citiesInput(preSetCity[0]);
          }
        }
      },
      (error) => {
        Sentry.captureException(new Error(error));
      }
    );
  }

  get submitAllowed(): boolean {
    if (
      this.sessionToken &&
      this.city &&
      this.commodity &&
      this.usage &&
      (this.priceType === 'single_price' || this.usageNt) &&
      (!this.streetNeeded || (this.street && this.houseNumber))
    ) {
      return true;
    }
    return false;
  }

  protected validateUsage(usage: number, usageNt: null | number): void {
    const isBusiness = this.isBusiness === 1;
    if (usage === null || (this.priceType !== 'single_price' && usageNt === null)) {
      this.errorUsage = true;
      this.errorUsageLimit = null;
    } else {
      usage += usageNt === null ? 0 : usageNt;
      if (!isBusiness && this.commodity === 'electricity' && usage > 20000 && this.currentClient === 'qcells') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit').toString();
        this.trackingProvider.sessionUsageLimitHigh();
      } else if (!isBusiness && this.commodity === 'gas' && usage > 50000 && this.currentClient === 'qcells') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit').toString();
        this.trackingProvider.sessionUsageLimitHigh();
      } else if (isBusiness && usage < 1000 && this.currentClient === 'qcells') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit2').toString();
        this.errorUsageLimit = '';
        this.trackingProvider.sessionUsageLimitBusinessLow();
      } else if (isBusiness && usage > 100000 && this.currentClient === 'qcells') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit').toString();
        this.trackingProvider.sessionUsageLimitBusinessHigh();
      } else if (usage > 10000 && this.commodity === 'electricity' && this.currentClient === 'sachsenenergie') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit').toString();
        this.trackingProvider.sessionUsageLimitHigh();
      } else if (usage > 50000 && this.commodity === 'gas' && this.currentClient === 'sachsenenergie') {
        this.errorUsage = false;
        this.errorUsageLimit = this.$t('errorUsageLimit').toString();
        this.trackingProvider.sessionUsageLimitHigh();
      } else {
        this.errorUsage = false;
        this.errorUsageLimit = null;
      }
    }
  }

  public validate(): void {
    this.errorCommodity = this.commodity === '';
    this.validateUsage(parseInt('' + this.usage, 10), parseInt('' + this.usageNt, 10));
    this.errorCity = this.city === '';
    this.errorStreet = this.streetNeeded && (this.street === '' || this.street === null);
    this.errorHouseNumber = this.streetNeeded && (this.houseNumber === '' || this.houseNumber === null);
  }

  public canCreateSession(): boolean {
    return !this.errorCommodity && !this.errorCity;
  }

  public canUpdateSession(): boolean {
    return !this.errorCommodity && !this.errorCity && !!this.sessionToken;
  }

  @Watch('street')
  @Watch('houseNumber')
  protected onAddressChange(): void {
    if (this.street !== '' && this.houseNumber !== '') {
      this.streetNeeded = true;
    }
  }
}
