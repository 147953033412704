import { injectable } from 'inversify';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Api from '../interfaces/Api';

@injectable()
export default class ApiProvider implements Api {
  public http: AxiosInstance;

  public sessionToken = '';

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/v1/',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: process.env.VUE_APP_API_AUTH,
        'WD-OVERWRITE-POWERCLOUD-APIS': process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true'
      }
    });
  }

  public async getMaintenanceSettings(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/maintenance/check', {
      sessionHash: this.sessionToken
    });
  }

  private setSessionToken(): void {
    const newSessionToken = localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
    if (newSessionToken) {
      this.sessionToken = newSessionToken;
    }
  }
}
