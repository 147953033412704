
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import i18n from '@/i18n';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Format from '@/interfaces/Format';

import Session from '@/interfaces/Session';
import Tracking from '@/interfaces/Tracking';
import Url from '@/interfaces/Url';
import HeatingOption from '@/interfaces/HeatingOption';

import { isMobileOnly, isMobile, isTablet } from 'mobile-device-detect';
import { AxiosResponse } from 'axios';

import Api from '@/interfaces/Api';
import moment from 'moment';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

interface CityData {
  text: string;
  zip: string;
  zipText: string;
  name: string;
}

if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local') {
  Sentry.init({
    dsn: 'https://69d39064a9474449b33cb190bbda3e81@sentry.io/5172853',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: 'sales-frontend-widget@' + process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_ENVIRONMENT + '.' + process.env.VUE_APP_ENVIRONMENT_STAGE,
    ignoreErrors: [
      /.*the name "wd-sales-widget" has already been used.*/,
      /.*'wd-sales-widget' has already been defined.*/
    ],
    blacklistUrls: [/.*sales-demo.workdigital.de.*/],
    beforeSend(event, hint) {
      if (hint && hint.originalException && hint.originalException instanceof Error) {
        const error = String(hint.originalException.stack);
        if (error) {
          if (error.match(/wd-sales-widget.min.js/i)) {
            return event;
          }
        }
      }
      return null;
    }
  });
}

// Elastic APM
if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local') {
  Vue.use(ApmVuePlugin, {
    config: {
      serviceName: 'sales-frontend-widget',
      serverUrl: process.env.VUE_APP_ELASTIC_APM_URL,
      serviceVersion: process.env.VUE_APP_VERSION,
      environment: process.env.VUE_APP_ENVIRONMENT_STAGE + '-' + process.env.VUE_APP_ENVIRONMENT,
      logLevel: 'warn',
      distributedTracingOrigins: ['https://sales-dev.workdigital.de', 'https://sales.workdigital.de']
      // agent configuration
    }
  });
}

@Component({
  props: {
    redirect: {
      type: String,
      default: process.env.VUE_APP_ENV_DEFAULT_REDIRECT ? process.env.VUE_APP_ENV_DEFAULT_REDIRECT : ''
    },
    widget: {
      type: String,
      default: ['local', 'test'].includes(process.env.VUE_APP_ENVIRONMENT_STAGE)
        ? process.env.VUE_APP_ENV_DEFAULT_WIDGET
        : ''
    },
    design: String,
    campaign: {
      type: String,
      default: ['local', 'test'].includes(process.env.VUE_APP_ENVIRONMENT_STAGE)
        ? process.env.VUE_APP_ENV_DEFAULT_CAMPAIGN
        : ''
    },
    enablesessionnotifier: {
      type: Boolean,
      default:
        process.env.VUE_APP_ENVIRONMENT_STAGE === 'local'
          ? process.env.VUE_APP_ENABLE_SESSION_NOTIFIER === 'true'
          : false
    },
    sessionredirectparam: {
      type: String,
      default:
        process.env.VUE_APP_ENVIRONMENT_STAGE === 'local'
          ? process.env.VUE_APP_DEFAULT_SESSION_REDIRECT_PARAM
          : 'session'
    },
    vpcodeparam: String,
    uvpcodeparam: String,
    business: Boolean,
    customerid: Number,
    zipcodeparam: String,
    usageparam: String,
    useexistingsession: {
      type: Boolean,
      default: false
    },
    skipredirect: Boolean,
    redirectWithUrlParam: {
      type: String,
      default: process.env.VUE_APP_REDIRECT_WITH_URL_PARAM || ''
    },
    customcss: String,
    defaultCommodity: String,
    defaultSubCommodity: String,
    addonparam: String,
    productcode: String,
    displayAddonInfo: String
  },
  i18n,
  filters: {
    date: formatProvider.date()
  }
})
export default class WdSalesWidgetPrototype extends Vue {
  public designClass = 'green';
  public isMobile = isMobileOnly;
  public isMobileAndTablet = isMobile;
  public isTablet = isTablet;

  protected redirect;
  protected widget;
  protected campaign;
  protected isBusiness: number = 0;
  protected customerId;
  protected enablesessionnotifier;

  protected submitting = false;

  protected sessionToken: string | null = null;

  protected commodity = 'electricity';
  protected subCommodity = '';
  protected user: number | null = null;
  protected usage: number | null = null;
  protected usageNt: number | null = null;
  protected consumptionType = 'household_electricity';
  protected priceType = 'single_price';
  protected zip = '';
  protected preSetZip = '';
  protected city = '';
  protected state = '';
  protected country = '';
  protected cityId = '';
  protected street = '';
  protected houseNumber = '';
  protected addon = '';
  protected productCode = '';
  protected streetFixed = 0;
  protected showAddonInfo: boolean | number = 0;

  protected product: any = null;
  protected addonDetails: any = null;

  protected citySaved: any = null;

  protected vpCode: string | null = null;
  protected uvpCode: string | null = null;
  protected extendedData: Record<any, any> = {};

  protected errorCommodity = false;
  protected errorUsage = false;
  protected errorUsageLimit: string | null = null;
  protected errorCity = false;
  protected errorStreet = false;
  protected errorHouseNumber = false;
  protected errorServer = false;

  protected streetNeeded = false;
  protected searchLengthReached = false;
  protected searchQuery = '';

  protected currentZipInFilter: any = null;

  protected cities: any = [];
  protected streets: any = [];

  protected isAvailableProducts = true;
  protected netCodeNumber: string | null = null;

  protected session = IocContainer.get<Session>(SERVICES.SESSION);
  protected trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
  protected urlProvider = IocContainer.get<Url>(SERVICES.URL);
  protected api = IocContainer.get<Api>(SERVICES.API);

  public validSessionToken: boolean = false;
  public sessionUpdatedAt: string = '';

  public isInMaintenance = false;
  public maintenanceText = '';

  private enableOldSession(): void {
    if (this.enablesessionnotifier) {
      if (this.streetFixed) {
        if (this.street !== '' && this.houseNumber !== '') {
          this.validSessionToken = true;
          this.transformWidget();
        } else {
          this.validSessionToken = false;
        }
      }
    }
  }

  public transformWidget(): void {
    const widgetRoot = document.querySelector('.wd-sales-widget');
    if (widgetRoot) {
      const allElementsWithClassAttribute = widgetRoot.querySelectorAll('[class]');
      this.addSessionMod(allElementsWithClassAttribute);
    }
  }

  private addSessionMod(elements: NodeListOf<Element>) {
    elements.forEach((element) => {
      if (typeof element.className === 'string') {
        const classNames = element.className.split(' ');

        for (let i = 0; i < classNames.length - 1; i++) {
          element.classList.add(classNames[i] + '--session-mod');
        }
      }
    });
  }

  get heatingOptions(): HeatingOption[] {
    return [
      {
        label: this.$t('heat_pump') + ', ' + this.$t('single_price'),
        consumptionType: 'heat_pump',
        priceType: 'single_price'
      },
      {
        label: this.$t('heat_pump') + ', ' + this.$t('multi_price'),
        consumptionType: 'household_electricity_and_heat_pump',
        priceType: 'multi_price'
      },
      {
        label: this.$t('storage_heater') + ', ' + this.$t('single_price'),
        consumptionType: 'storage_heater',
        priceType: 'single_price'
      },
      {
        label: this.$t('storage_heater') + ', ' + this.$t('multi_price'),
        consumptionType: 'household_electricity_and_storage_heater',
        priceType: 'multi_price'
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public heatingInput(value): void {
    this.consumptionType = value.consumptionType;
    this.priceType = value.priceType;
  }

  public validate(): void {
    return;
  }

  public canCreateSession(): boolean {
    return false;
  }

  public sessionCreated(response: AxiosResponse<any>): void {
    if (response.data.data.street_required) {
      this.trackingProvider.sessionStreetRequired();
      this.streetNeeded = true;
      this.streets = response.data.data.streets;
      if (process.env.VUE_APP_COUNTRY_CODE === 'us') {
        // Custom Code for US Demo
        this.streets.map((street) => {
          if (street.name === 'Bundesbahnhof') {
            street.name = 'Broadway';
          }
          return street;
        });
      }
    }
    if (response.data.data.product) {
      this.product = response.data.data.product;
      const addonCode = this.addon;
      this.addonDetails = response.data.data.addon.find((item) => item.productCode === addonCode);
    }
  }

  public canUpdateSession(): boolean {
    return false;
  }

  public sessionUpdated(response: AxiosResponse<any>): void {
    if (response.data.data.street_required) {
      this.trackingProvider.sessionStreetRequired();
      this.streetNeeded = true;
      this.streets = response.data.data.streets;
    }
  }

  public houseNumberInput(value: string): void {
    this.houseNumber = value;
  }

  get electricityActive(): boolean {
    return process.env.VUE_APP_COMMODITY_ELECTRICITY ? process.env.VUE_APP_COMMODITY_ELECTRICITY === '1' : true;
  }

  get gasActive(): boolean {
    return process.env.VUE_APP_COMMODITY_GAS ? process.env.VUE_APP_COMMODITY_GAS === '1' : true;
  }

  get heatingActive(): boolean {
    return process.env.VUE_APP_COMMODITY_HEATING ? process.env.VUE_APP_COMMODITY_HEATING === '1' : true;
  }

  get electricityActiveOnly(): boolean {
    return this.electricityActive && !this.gasActive && !this.heatingActive;
  }

  get gasActiveOnly(): boolean {
    return !this.electricityActive && this.gasActive && !this.heatingActive;
  }

  get heatingActiveOnly(): boolean {
    return !this.electricityActive && !this.gasActive && this.heatingActive;
  }

  get citiesDisplay(): CityData[] | [] {
    if (this.searchLengthReached) {
      const search = parseInt(this.searchQuery, 10) + '';
      const filteredCities = this.cities.filter((city) => {
        if (this.isNumeric(this.searchQuery)) {
          return new RegExp('^' + search).test(city.zip);
        } else {
          return new RegExp('^' + this.searchQuery, 'i').test(city.name);
        }
      });

      this.currentZipInFilter = null;

      let filteredByZip = filteredCities.every((city) => {
        if (this.currentZipInFilter === null) {
          this.currentZipInFilter = city.zip;
          return true;
        }
        return this.currentZipInFilter === city.zip;
      });

      if (this.searchQuery.charAt(0) === '0') {
        filteredByZip = true;
      }

      return this.citiesNormalizing(filteredCities, filteredByZip && filteredCities.length > 1 ? 'text' : 'zip');
    } else {
      return [];
    }
  }

  get streetsDisplay(): [] {
    return this.streets.map((street) => {
      return street.name;
    });
  }

  get currentClient(): string {
    if (process.env.VUE_APP_ENVIRONMENT == 'qcellsNew') return 'qcells';

    return process.env.VUE_APP_ENVIRONMENT;
  }

  protected setExtendedData(category: string, value: any): void {
    if (!this.extendedData) {
      this.extendedData = {};
    }
    this.extendedData[category] = value;
  }

  public created(): void {
    this.api.getMaintenanceSettings().then((res) => {
      let isMaintenance = false;
      if (res.data.maintenanceStart) {
        const startDate = moment(res.data.maintenanceStart);
        if (moment().diff(startDate) > 0) {
          isMaintenance = true;
        }
        if (res.data.maintenanceEnd) {
          const endDate = moment(res.data.maintenanceEnd);
          if (moment().diff(endDate) > 0) {
            isMaintenance = false;
          }
        }
      }

      this.maintenanceText = res.data.maintenanceText;
      this.isInMaintenance = isMaintenance;
    });
    this.widget = this.$props.widget || process.env.VUE_APP_ENV_DEFAULT_WIDGET;
    if (this.$props.defaultCommodity) {
      this.commodity = this.$props.defaultCommodity;
    }
    if (this.$props.defaultSubCommodity) {
      this.subCommodity = this.$props.defaultSubCommodity;
    }
    if (this.$props.design && ['green', 'blue', 'cyan'].includes(this.$props.design)) {
      this.designClass = this.$props.design;
    }
    this.campaign = this.$props.campaign || process.env.VUE_APP_ENV_DEFAULT_CAMPAIGN;
    if (this.$props.business && this.$props.business === true) {
      this.isBusiness = 1;
    } else {
      this.isBusiness = 0;
    }
    if (this.$props.productcode) {
      this.productCode = this.$props.productcode;
    }
    if (this.$props.customerid && this.$props.customerid !== '') {
      this.customerId = this.$props.customerid;
    } else {
      this.customerId = null;
    }
    if (this.$props.displayAddonInfo) {
      this.showAddonInfo = !(this.$props.displayAddonInfo == 'false' || this.$props.displayAddonInfo == 0);
    }
    if (this.$props.vpcodeparam !== '' && this.urlProvider.hasKey(window.location.href, this.$props.vpcodeparam)) {
      this.vpCode = this.urlProvider.getKey(window.location.href, this.$props.vpcodeparam);
    }
    if (this.$props.uvpcodeparam !== '' && this.urlProvider.hasKey(window.location.href, this.$props.uvpcodeparam)) {
      this.uvpCode = this.urlProvider.getKey(window.location.href, this.$props.uvpcodeparam);
    }
    if (this.$props.zipcodeparam !== '' && this.urlProvider.hasKey(window.location.href, this.$props.zipcodeparam)) {
      this.preSetZip = this.urlProvider.getKey(window.location.href, this.$props.zipcodeparam);
    }
    if (this.$props.usageparam !== '' && this.urlProvider.hasKey(window.location.href, this.$props.usageparam)) {
      this.usage = Number(this.urlProvider.getKey(window.location.href, this.$props.usageparam));
    }
    if (this.$props.addonparam !== '' && this.urlProvider.hasKey(window.location.href, this.$props.addonparam)) {
      this.addon = this.urlProvider.getKey(window.location.href, this.$props.addonparam);
    }
    if (this.$props.useexistingsession && this.$props.useexistingsession === true) {
      this.readSessionState().then(() => {
        if (this.zip && this.city) {
          this.citySaved = this.zip + ' ' + this.city;
        }
      });
    }

    if (this.currentClient.includes('elli')) {
      if (this.urlProvider.hasKey(window.location.href, 'utm_source')) {
        this.vpCode = this.urlProvider.getKey(window.location.href, 'utm_source');
      }
      if (this.urlProvider.hasKey(window.location.href, 'utm_id')) {
        this.uvpCode = this.urlProvider.getKey(window.location.href, 'utm_id');
      }
    }

    if (this.$props.customcss && this.$props.customcss.length > 0) {
      const customCss = document.createElement('link');
      customCss.setAttribute('rel', 'stylesheet');
      customCss.setAttribute('href', this.$props.customcss);
      customCss.setAttribute('id', 'wd-sales-widget-custom-css');
      const widgets = document.getElementsByTagName('wd-sales-widget');
      const amountWidgets = widgets.length;
      for (let i = 0; i < amountWidgets; i++) {
        // only add css to specified widget
        if (widgets.item(i)?.getAttribute('widget') === this.widget) {
          const shadow = widgets.item(i)?.shadowRoot;
          if (shadow) {
            if (!shadow.getElementById('wd-sales-widget-custom-css')) {
              shadow.appendChild(customCss);
              break;
            }
          }
        }
      }
    } else if (process.env.VUE_APP_CUSTOM_CSS && process.env.VUE_APP_CUSTOM_CSS.length > 0) {
      const customCss = document.createElement('link');
      customCss.setAttribute('rel', 'stylesheet');
      customCss.setAttribute('href', process.env.VUE_APP_CUSTOM_CSS);
      customCss.setAttribute('id', 'wd-sales-widget-custom-css');
      const widgets = document.getElementsByTagName('wd-sales-widget');
      const amountWidgets = widgets.length;
      if (widgets.length > 0) {
        for (let i = 0; i < amountWidgets; i++) {
          // only add css to specified widget
          if (widgets.item(i)?.getAttribute('widget') === this.widget) {
            const shadow = widgets.item(i)?.shadowRoot;
            if (shadow) {
              if (!shadow.getElementById('wd-sales-widget-custom-css')) {
                shadow.appendChild(customCss);
                break;
              }
            }
          }
        }
      } else {
        document.head.appendChild(customCss);
      }
    }

    //FOR CYPRESS TESTING
    if (this.urlProvider.hasKey(window.location.href, 'cypress-test-commodity')) {
      this.commodity = this.urlProvider.getKey(window.location.href, 'cypress-test-commodity');
    }
    if (this.urlProvider.hasKey(window.location.href, 'cypress-test-sub-commodity')) {
      this.subCommodity = this.urlProvider.getKey(window.location.href, 'cypress-test-sub-commodity');
    }
  }

  public async readSessionState(): Promise<void | AxiosResponse<any>> {
    this.sessionToken = localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
    if (this.sessionToken) {
      return await this.session
        .sessionRead(this.sessionToken)
        .then((response) => {
          if (response.status === 200) {
            this.isBusiness = response.data.business;
            this.campaign = response.data.campaign;
            this.city = response.data.city;
            this.cityId = response.data.cityId;
            this.commodity = response.data.commodity;
            this.consumptionType = response.data.consumptionType;
            this.priceType = response.data.priceType;
            this.houseNumber = response.data.houseNumber || '';
            this.street = response.data.street || '';
            this.streetFixed = response.data.streetFixed;
            this.usage = response.data.usage;
            this.usageNt = response.data.usageNt;
            this.widget = response.data.widget;
            this.zip = response.data.zip;
            this.product = response.data.product;
            this.sessionUpdatedAt = response.data.updatedAt;
            if (this.addon) {
              const addonCode = this.addon;
              this.addonDetails = response.data.addon.find((item) => item.productCode === addonCode);
            }
            this.enableOldSession();
          }
        })
        .catch((error) => {
          if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'prod') {
            console.log(error);
          }
          this.sessionToken = null;
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
        });
    }
  }

  public citySearch(search: string): void {
    if (search.length >= 3) {
      this.searchQuery = search;
      this.searchLengthReached = true;
    } else {
      this.searchQuery = search;
      this.searchLengthReached = false;
    }
  }

  public commodityChanged(event: Event): void {
    this.street = '';
    this.houseNumber = '';
    this.streetNeeded = false;
    this.commodity = (event.target as HTMLInputElement).value;
    if (this.commodity === 'electricity') {
      this.consumptionType = 'household_electricity';
    } else if (this.commodity === 'gas') {
      this.consumptionType = 'universal';
    }
    this.createOrUpdateSession(true);
  }

  public citiesInput(value: { id: string; zip: string; name: string }): void {
    if (value) {
      this.cityId = value.id;
      this.zip = value.zip;
      this.city = value.name;
      // this.cityError = false;
      this.street = '';
      this.houseNumber = '';
      this.streetNeeded = false;
      this.createOrUpdateSession();
    }
  }

  public streetInput(value: string): void {
    this.street = value;
  }

  public isNumeric(value: string): boolean {
    return /^-{0,1}\d+$/.test(value);
  }

  public usageChanged(value: number): void {
    this.usage = value;
  }

  protected createOrUpdateSession(isCommodity = false): void {
    if (this.sessionToken) {
      this.trackingProvider.sessionUpdate();
      if (isCommodity) {
        this.updateSessionCommodity();
      } else {
        this.updateSession();
      }
    } else {
      if (this.commodity && this.city) {
        this.trackingProvider.sessionCreate();
        this.createSession();
      }
    }
  }

  protected citiesNormalizing(filteredCities: CityData[], type: string): CityData[] {
    return filteredCities.map((city) => {
      const cityNormalized = { ...city };
      if (cityNormalized.zip.length < 5) {
        cityNormalized.text = '0' + city.text;
        cityNormalized.zip = '0' + city.zip;
      }
      cityNormalized.zipText = type === 'zip' ? cityNormalized.zip : cityNormalized.text;
      return cityNormalized;
    });
  }

  protected async createSession(): Promise<void> {
    if (this.canCreateSession()) {
      let genericStorage: any = null;
      if (this.vpCode === 'aklamio') {
        genericStorage = {
          aid: this.urlProvider.getKey(window.location.href, 'aid'),
          coupon: this.urlProvider.getKey(window.location.href, 'coupon')
        };
      }
      await this.session
        .create(
          this.widget,
          this.campaign,
          this.isBusiness,
          this.usage,
          this.usageNt,
          this.consumptionType,
          this.priceType,
          this.commodity,
          this.zip,
          this.city,
          this.state,
          this.country,
          this.cityId,
          this.street,
          this.houseNumber,
          this.vpCode,
          this.uvpCode,
          this.customerId,
          this.productCode,
          this.addon,
          this.subCommodity,
          this.extendedData,
          genericStorage
        )
        .then(
          (response) => {
            if (response.data.data.status === 'created') {
              this.trackingProvider.sessionCreated(this.usage);
              this.sessionToken = response.data.data.session;
              localStorage.setItem(process.env.VUE_APP_SESSION_STORAGE_KEY, response.data.data.session);
              this.sessionCreated(response);
              this.errorServer = false;
            } else {
              this.errorServer = true;
            }
          },
          (error) => {
            this.errorServer = true;
            this.trackingProvider.sessionCreationError();
            Sentry.captureException(new Error(error));
          }
        );
    }
  }

  protected async updateSessionCommodity(): Promise<void> {
    if (this.canUpdateSession() && this.sessionToken) {
      await this.session
        .updateCommodity(
          this.sessionToken,
          this.widget,
          this.campaign,
          this.usage,
          this.commodity,
          this.zip,
          this.city,
          this.state,
          this.country,
          this.cityId,
          this.street,
          this.houseNumber,
          this.subCommodity
        )
        .then(
          (response) => {
            if (response.data.data.status === 'sessionUpdated') {
              this.trackingProvider.sessionUpdated();
              this.sessionUpdated(response);
              this.errorServer = false;
            } else {
              this.errorServer = true;
            }
          },
          (error) => {
            this.errorServer = true;
            this.trackingProvider.sessionUpdateError();
            Sentry.captureException(new Error(error));
          }
        );
    }
  }

  protected async updateSession(): Promise<void> {
    if (this.canUpdateSession() && this.sessionToken) {
      let genericStorage: any = null;
      if (this.vpCode === 'aklamio') {
        genericStorage = {
          aid: this.urlProvider.getKey(window.location.href, 'aid'),
          coupon: this.urlProvider.getKey(window.location.href, 'coupon')
        };
      }
      await this.session
        .update(
          this.sessionToken,
          this.widget,
          this.campaign,
          this.usage,
          this.zip,
          this.city,
          this.state,
          this.country,
          this.cityId,
          this.street,
          this.houseNumber,
          genericStorage
        )
        .then(
          (response) => {
            if (response.data.data.status === 'sessionUpdated') {
              this.trackingProvider.sessionUpdated();
              this.sessionUpdated(response);
              this.errorServer = false;
            } else {
              this.errorServer = true;
            }
          },
          (error) => {
            this.errorServer = true;
            this.trackingProvider.sessionUpdateError();
            Sentry.captureException(new Error(error));
          }
        );
    }
  }

  protected async submitWidget(): Promise<void> {
    this.validate();
    let withProduct = false;
    if (this.productCode) {
      withProduct = true;
    }
    if (
      !this.errorCity &&
      !this.errorStreet &&
      !this.errorHouseNumber &&
      !this.errorUsage &&
      !this.errorUsageLimit &&
      this.sessionToken &&
      this.usage &&
      (this.priceType === 'single_price' || this.usageNt)
    ) {
      this.submitting = true;
      await this.session
        .submitWidget(
          this.sessionToken,
          this.usage,
          this.usageNt,
          this.consumptionType,
          this.priceType,
          this.street,
          this.houseNumber,
          withProduct
        )
        .then(
          (response) => {
            this.submitting = false;
            if (response.data.data.status === 'widgetSubmit') {
              this.trackingProvider.sessionSubmit({ usage: this.usage, user: this.user });
              if (parseInt(response.data.data.products_checked, 10) === 1 && response.data.data.product_amount === 0) {
                this.isAvailableProducts = false;
                this.netCodeNumber = response.data.data.netCodeNumber || null;
                if (this.$props.skipredirect) {
                  window.dispatchEvent(new CustomEvent('wd-session-updated'));
                }
              } else {
                this.isAvailableProducts = true;
                if (this.$props.redirectWithUrlParam !== '') {
                  window.location.href =
                    this.redirect + '?' + this.$props.redirectWithUrlParam + '=' + response.data.data.session;
                } else if (!this.$props.skipredirect) {
                  window.location.href = this.redirect;
                } else {
                  window.dispatchEvent(new CustomEvent('wd-session-updated'));
                }
              }
            } else {
              this.trackingProvider.sessionSubmitError();
            }
          },
          (error) => {
            this.submitting = false;
            this.trackingProvider.sessionSubmitError();
            Sentry.captureException(new Error(error));
          }
        );
    }
  }
}
