import { injectable } from 'inversify';
import Tracking from '../interfaces/Tracking';

@injectable()
export default class TrackingProvider implements Tracking {
  public dataLayerTag = 'dataLayer';
  public async sessionCreate(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Create']);
    if (process.env.VUE_APP_ENVIRONMENT === 'qcells') {
      // Do some more tracking based calls for this specific client
    }
  }

  public async googleHouseNumberMissing(): Promise<void> {
    // window._paq.push(['trackEvent', 'Google', 'House Number Missing']);
  }

  public async googleStreetMissing(): Promise<void> {
    // window._paq.push(['trackEvent', 'Google', 'Street Missing']);
  }

  public async sessionUsageLimitHigh(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Usage to High']);
  }

  public async sessionUsageLimitBusinessLow(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Usage to Low - Business']);
  }

  public async sessionUsageLimitBusinessHigh(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Usage to High - Business']);
  }

  public async sessionCreated(usage: number | null): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Created']);
    if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
      if (typeof window['gtag'] !== 'undefined') {
        window['gtag']('event', 'used', { event_category: 'Naturstrom Widget', event_label: '' });
      }
    } else if (process.env.VUE_APP_ENVIRONMENT === 'prokon') {
      if (typeof window['gtag'] !== 'undefined') {
        window['gtag']('event', 'used', { event_category: 'Prokon Widget', event_label: '' });
      }
    }
  }

  public async sessionCreationError(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Creation Error']);
  }

  public async sessionUpdate(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Update']);
  }

  public async sessionUpdated(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Updated']);
  }

  public async sessionUpdateError(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Update Error']);
  }

  public async sessionStreetRequired(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Street Required']);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async sessionSubmit(data: any): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Submit']);
    if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
      if (typeof window['fbq'] !== 'undefined') {
        window['fbq']('track', 'Lead', { content_category: 'Volkswagen Naturstrom' });
      }
      window[this.dataLayerTag] = window[this.dataLayerTag] || [];
      window[this.dataLayerTag].push({
        event: 'submit_naturstrom_calculator',
        persons_in_household: data.user,
        kWh_yearly: data.usage
      });
    } else if (process.env.VUE_APP_ENVIRONMENT === 'prokon') {
      if (typeof window['fbq'] !== 'undefined') {
        window['fbq']('track', 'Lead', { content_category: 'Prokon' });
      }
    }
  }

  public async sessionSubmitError(): Promise<void> {
    // window._paq.push(['trackEvent', 'Session', 'Submit Error']);
  }
}
